import React, { useState } from "react"
import PostCardForPostsPage from "../molecules/PostCardForPostsPage"
import SearchBox from "../molecules/SearchBox"
import useReadingTime from "../../hooks/useReadingTime"

const PostsWithSearchPaginationSection = ({
  title,
  searchPlaceholder,
  posts,
  posttypeSlug,
}) => {
  const uniqueCategories = [...new Set(posts.map(post => post.category))]
  const POSTS_PER_PAGE = 6

  const [selectedCategory, setSelectedCategory] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")

  const filteredBlogPosts = posts.filter(post => {
    return (
      (selectedCategory ? post.category === selectedCategory : true) &&
      (searchQuery
        ? post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          post.description.toLowerCase().includes(searchQuery.toLowerCase())
        : true)
    )
  })

  const totalPages = Math.ceil(filteredBlogPosts.length / POSTS_PER_PAGE)

  const paginatedBlogPosts = filteredBlogPosts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  )

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber)
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleSearchChange = e => {
    setSearchQuery(e.target.value)
    setCurrentPage(1)
  }

  const { calculateReadingTime } = useReadingTime()

  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <div className="flex flex-wrap gap-5 items-center justify-between">
        <h2 className="text-[38px] md:text-[40px] leading-[1.5em] font-[600] text-[#2A2A2A]">
          {title || "All Posts"}
        </h2>
        <SearchBox
          placeholder={searchPlaceholder || "Search here"}
          onChange={handleSearchChange}
          value={searchQuery}
        />
      </div>

      <div className="flex flex-wrap gap-4 mt-8 md:mt-16">
        <button
          type="button"
          onClick={() => {
            setSelectedCategory("")
            setCurrentPage(1)
          }}
          className={`bg-[#F2F1FA] rounded-[10px] px-4 py-2 md:py-3 text-[16px] md:text-[18px] font-[400] leading-[1.2em] text-[#000000] transition hover:bg-[#000000] hover:text-[#F2F1FA] ${
            selectedCategory === "" ? "!bg-[#000000] !text-[#F2F1FA]" : ""
          }`}
        >
          All
        </button>
        {uniqueCategories.map((category, index) => (
          <button
            key={index}
            type="button"
            onClick={() => {
              setSelectedCategory(category)
              setCurrentPage(1)
            }}
            className={`bg-[#F2F1FA] rounded-[10px] px-4 py-2 md:py-3 text-[16px] md:text-[18px] font-[400] leading-[1.2em] text-[#000000] transition hover:bg-[#000000] hover:text-[#F2F1FA] ${
              selectedCategory === category
                ? "!bg-[#000000] !text-[#F2F1FA]"
                : ""
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="flex flex-wrap justify-start mt-12">
        {paginatedBlogPosts.map((post, index) => (
          <div key={index} className="w-full md:w-1/2 lg:w-1/3 px-2 mt-5">
            <PostCardForPostsPage
              posttypeSlug={posttypeSlug}
              componentEC="w-full"
              date={post.date}
              category={post.category}
              title={post.title}
              slug={post.slug}
              description={post.description}
              cardImage={post.cardImage}
              time={calculateReadingTime(post.postContent)}
            />
          </div>
        ))}
      </div>

      <div className="flex flex-wrap justify-center mt-16 lg:mt-28">
        <button
          type="button"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`mx-1 my-1 px-3 py-1 sm:px-4 sm:py-3 border border-[#BBBBBB] rounded ${
            currentPage === 1 ? "text-[#979797]" : "text-[#000000]"
          } transition hover:bg-[#B695F8] hover:text-[#F2F1FA]`}
        >
          Previous
        </button>

        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            type="button"
            onClick={() => handlePageChange(index + 1)}
            className={`mx-1 my-1 px-3 py-1 sm:px-5 sm:py-3 border border-[#BBBBBB] rounded ${
              currentPage === index + 1
                ? "bg-[#B695F8] text-[#F2F1FA]"
                : "text-[#000000]"
            } transition hover:bg-[#B695F8] hover:text-[#F2F1FA]`}
          >
            {index + 1}
          </button>
        ))}

        <button
          type="button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`mx-1 my-1 px-3 py-1 sm:px-4 sm:py-3 border border-[#BBBBBB] rounded ${
            currentPage === totalPages ? "text-[#979797]" : "text-[#000000]"
          } transition hover:bg-[#B695F8] hover:text-[#F2F1FA]`}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default PostsWithSearchPaginationSection
