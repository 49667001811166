import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostsWithSearchPaginationSection from "../components/common/PostsWithSearchPaginationSection"
import BusinessModelsWithFormSection from "../components/common/BusinessModelsWithFormSection"
import { caseStudies, caseStudiesPageContent } from "../Content/CaseStudies"
import InnerPageBannerWithTitleDescription from "../components/common/InnerPageBannerWithTitleDescription"
import { caseStudiesPostTypeSlug } from "../Content/Global"

const Blogs = () => (
  <Layout>
    <Seo
      title="Industry News, Tips and Software Updates"
      description={`Explore our blog for tips and updates on expense tracking, tax management, and financial tools. Optimise your financial strategy with Synkli.`}
    />

    <InnerPageBannerWithTitleDescription
      title={caseStudiesPageContent.title}
      description={caseStudiesPageContent.description}
      extraDescription={caseStudiesPageContent.extraDescription}
      extraDescriptionEC={`font-[600]`}
      image={{
        path: caseStudiesPageContent.image.path,
        alt: caseStudiesPageContent.image.alt,
      }}
      imageWrapperEC={`self-end hidden md:block`}
    />

    <PostsWithSearchPaginationSection
      title={`Case Studies`}
      searchPlaceholder={`Search Case Study`}
      posts={caseStudies}
      posttypeSlug={caseStudiesPostTypeSlug}
    />

    <div className="pb-10">
      <BusinessModelsWithFormSection
        title={"Synkli – Your Ultimate Business Management Partner"}
        description={`Elevate your business with Synkli, your comprehensive business management solution. Streamline operations, enhance efficiency, and drive growth with our intuitive software.`}
      />
    </div>
  </Layout>
)

export default Blogs
